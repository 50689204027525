import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import * as homeStyles from './index.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselLogo from '../components/CarouselLogo'
import { FaArrowAltCircleDown } from 'react-icons/fa'
import SEO from '../components/SEO'

export default function Home() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const data = useStaticQuery(query)
    const clients = data.contentfulCarousel.clients

    return (
        <Layout>
            <SEO title='Poolusfilm' description='Jäädvustame Lugusid' />
            <main>
                {/* <section>
                    <div className={homeStyles.videocontainer}>
                        <iframe className={homeStyles.video}
                            src='https://player.vimeo.com/video/311107349?h=bbf696fcae&autoplay=1&loop=1&muted=1&title=0&byline=0&portrait=0&background=1'
                            frameBorder='0'
                            allow='autoplay; fullscreen; picture-in-picture'
                            allowFullScreen
                            title='Showreel'>
                        </iframe>
                        <div className={homeStyles.mainheading}>
                            <h1>JÄÄDVUSTAME LUGUSID</h1>
                            <Link to='/portfolio'>
                                <button type='button'>MEIE TÖÖD</button>
                            </Link>
                            <a href='#meist'>
                                <FaArrowAltCircleDown className={homeStyles.arrowicon} />
                            </a>
                        </div>
                    </div>
                </section>
                <section className={homeStyles.page}>
                    <div className={homeStyles.info}>
                        <div>
                            <h2 id='meist'>Mälestused kustuvad, videod on igaveseks</h2>
                            <p>Oleme videoproduktsiooni ettevõte, kes tegeleb peamiselt reklaam ja üritusvideote filmimise, monteerimise ja produktsiooniga.
                                Meie prioriteetideks on kvaliteet ja kliendi rahulolu. Poolusfilm on tegutsenud kaks aastat, kuid meie juured ulatuvad aastasse 2015.</p>
                            <p>Oma tegutsemisaja jooksul oleme jäädvustanud mitmeid eri suuruses projekte ning leidnud kümneid ägedaid koostööpartnereid!
                                Tegutseme peamiselt Tallinnas ning oma kodukohas Ida-Virumaal, kuid oleme valmis väljakutseteks üle terve Eesti!</p>
                            <p>Võta meiega ühendust!</p>
                            <Link to='/meist'>
                                <button type='button'>UURI VEEL</button>
                            </Link>
                        </div>
                    </div>
                </section>
                <section className={homeStyles.servicescontainer}>
                    <div className={homeStyles.services}>
                        <div className={homeStyles.servicelist}>
                            <h5>Teenused</h5>
                            <h3>Pakume erinevaid kvaliteetseid ja kliendisõbralike videoteenuseid</h3>
                            <h4>Reklaamvideod</h4>
                            <p>Planeerime ning produtseerime Teie tootele või teenusele unikaalse reklaamvideo ning vajadusel aitame ka turundamisega.</p>
                            <h4>Üritusvideod</h4>
                            <p>Jäädvustame Teie ürituse ägedaimad hetked ning paneme sellest kokku kvaliteetse <em>aftermovie!</em></p>
                            <h4>Montaaž</h4>
                            <p>Teie videomaterjal ja visioon + Meie kogemus ja oskused - sellest võib kokku tulla midagi väga erilist!</p>
                        </div>
                        <div className={homeStyles.contact}>
                            <div>
                                <h2>Võta ühendust ja räägime Teie visioonist lähemalt!</h2>
                                <Link to='/kontakt'>
                                    <button type='button'>KONTAKT</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={homeStyles.clients}>
                    <div className={homeStyles.clientheader}>
                        <h2>Meie kliendid</h2>
                    </div>
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        centerMode={false}
                        containerClass={homeStyles.imagecarousel}
                        draggable
                        infinite
                        itemClass=''
                        className=''
                        dotListClass=''
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive}
                        showDots={false}
                        sliderClass=''
                        slidesToSlide={1}
                        swipeable
                    >
                        {clients.map(client => <CarouselLogo client={client} key={client.id} />)}
                    </Carousel>
                </section> */}
                <div className={homeStyles.updating}>
                    <h1>Poolusfilm</h1>
                    <p>Leht uuendamisel</p>
                </div>
            </main>
        </Layout>
    )
}

export const query = graphql`
  {
    contentfulCarousel {
      clients {
        title
        id
        gatsbyImageData(
          width: 150
          height: 150
          layout: CONSTRAINED
          placeholder: BLURRED
        )
      }
    }
  }
`